.fs-1{
    color: #966E4F;;
}

.line{
    width: 5em;
    margin: 0;
  color: #966E4F;
}

.text-center{
    margin: 0%;
    font-family: "montserrat";
}