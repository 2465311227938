@import url('https://fonts.googleapis.com/css2?family=Lancelot&display=swap');

:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color-scheme: light dark;
    background-color: #FCFAF7;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      }

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #FCFAF7;
}

.App{
    max-width: 1040;
    min-height: 100vh;

}
